function showMenu(event) {
  event.stopPropagation();
  event.currentTarget.classList.add('ev-dropdown-container--open');
}

function hideMenu(event) {
  event.stopPropagation();
  event.currentTarget.classList.remove('ev-dropdown-container--open');
}

let visibility = false;

function handleClick(event) {
  if(visibility) {
    event.currentTarget.classList.remove('ev-dropdown-container--mobile-open');
    visibility = false;
    event.currentTarget.querySelector('button').innerHTML = '+'
  } else {
    event.currentTarget.classList.add('ev-dropdown-container--mobile-open');
    visibility = true;
    event.currentTarget.querySelector('button').innerHTML = '-'
  }
}

document.querySelectorAll('.ev-dropdown-container').forEach(element => {
  element.addEventListener('mouseenter', showMenu);
  element.addEventListener('mouseleave', hideMenu);
  element.addEventListener('click', handleClick);
});
